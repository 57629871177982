<template>
  <div id="app" class="bg-black text-white min-h-screen">


    <router-view />

  </div>
</template>



<script>

// import { mapGetters } from "vuex";

export default {
  metaInfo: {
    /*
    link: [
      {
        rel: 'apple-touch-icon',
        href: 'https://api.stadtbahnfahrer.club/assets/ee11bed5-1300-428c-b031-b16904e2a708',
        type: 'image/png',
        sizes: "180x180",
      },
      {
        rel: 'icon',
        href: 'https://api.stadtbahnfahrer.club/assets/164fe890-04c9-4f8b-b0af-a4d75bf92618',
        type: 'image/png',
        sizes: "32x32",
      },
      {
        rel: 'icon',
        href: 'https://api.stadtbahnfahrer.club/assets/eed1dbfc-4863-4bc3-a9fd-8d31b6529287',
        type: 'image/png',
        sizes: "16x16",
      },
      {
        rel: 'manifest',
        href: 'https://api.stadtbahnfahrer.club/assets/afb4bfdf-9564-409c-8e18-15db61a32c81.webmanifest',
      },
      {
        rel: 'mask-icon',
        href: 'https://api.stadtbahnfahrer.club/assets/47c8d31b-6bfb-479f-aab7-97a064d52c70',
        // color: "#5bbad5"
      },
      {
        rel: 'shortcut icon',
        href: 'https://api.stadtbahnfahrer.club/assets/27c37dcd-9665-4fca-80a8-910b75d4871d',
      }
    ],
    */
    // if no subcomponents specify a metaInfo.title, this title will be used
    // title: 'Willkommen',
    // all titles will be injected into this template
    // titleTemplate: 'StadtbahnfahrerClub | %s '
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      return titleChunk ? `YellowCat Development | ${titleChunk}` : 'YellowCat Development';
    },
    htmlAttrs: {
      lang: 'de'
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'YellowCat Development',
        vmid: 'og:title'
      },
      {
       name: 'msapplication-TileColor',
       content: '#030403'
      },
      {
       name: 'theme-color',
       content: '#ffffff'
      }
    ],
    noscript: [
      { innerHTML: 'Willkommen beim YellowCat Development. Aktiviere bitte JavaScript in Deinem Internet-Browser, um unsere Website nutzen zu können.' }
    ]
  },
  
};
</script>