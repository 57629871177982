import Vue from 'vue'
import router from './router'
import App from './App.vue'
import VueMatomo from 'vue-matomo'

import './assets/index.css';

Vue.config.productionTip = false

import VueMeta from 'vue-meta'
Vue.use(VueMeta)


Vue.use(VueMatomo, {
  host: "https://analytics.yellowcat.dev/",
  siteId: 3,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: true,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')