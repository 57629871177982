import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    // component: () => import(/* webpackChunkName: "about" */ '../views/Preview.vue')
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Home',
      requiresAuth: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// navigation guard to check for logged in users
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//   const requiresVerified = to.matched.some(x => x.meta.requiresVerified)
//   // const routerName = to.name

//   store.dispatch("fetchUserProfile").then(function(a) {
//     console.log(a)
//     if (requiresAuth && !store.state.currentUser) {

//       // console.log(routerName);

//       // if(routerName == "ExchangeDetails") {
//       //   console.log(to.fullPath);
//       //   next()
//       //   return
//       // }

//       console.log("router: requiresAuth but none currentUser, go to login")
//       console.log(to)
//       console.log(to.fullPath)
//       store.state.redirectedFrom = to.fullPath
//       next('/anmelden')
//     } else {
//       if (requiresAuth && requiresVerified && store.state.currentUser && !store.state.currentUser.verified) {
//         console.log("router: requiresVerified but currentUser is not verified, go to dashboard")
//         next('/dashboard')
//       } else {
//         console.log("router: next()")
//         next()
//       }
//     }
//   })

  
// })

export default router
